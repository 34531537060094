import Vue from 'vue'
import Vuex from 'vuex'
import store from  './store/store'
import App from './App.vue'
import router from './router'
import "./assets/css/common.less"
import Antd from 'ant-design-vue'
import 'ant-design-vue/dist/antd.css'
import bootstrap from '@/bootstrap'
Vue.use(Vuex)
Vue.config.productionTip = false

import TIM from 'tim-js-sdk';
import TIMUploadPlugin from 'tim-upload-plugin';
let options = {
  SDKAppID:1400737729
}

let tim = TIM.create(options);
tim.setLogLevel(0);
tim.registerPlugin({'tim-upload-plugin': TIMUploadPlugin});
Vue.prototype.$tim = tim
Vue.prototype.$TIM = TIM

Vue.prototype.global={
  max_pic:20,//图片上传最大限制20M
}

Vue.use(Antd)

bootstrap({router, store,message: Vue.prototype.$message})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
