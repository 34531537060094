import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

const routes = [
  {
    path: '/chat',
    name: '聊天',
    component: () => import('@/pages/chat.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})
router.beforeEach( (to,from,next) => {
  if(to.name){
    document.title = to.name
  }
  next()
})
export default router
