import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)
export default new Vuex.Store({
  state: {
		userInfo:null
  },
  getters: {
    userInfo:state=>{
      if (!state.userInfo) {
        try {
          const userInfo = sessionStorage.getItem(process.env.VUE_USER_INFO)
          state.userInfo = JSON.parse(userInfo)
        } catch (e) {
          console.error(e)
        }
      }
      return state.userInfo
    },
  },
  mutations: {
    setuserInfo(state,value){
      if(value==undefined){
        state.userInfo = null
        sessionStorage.removeItem(process.env.VUE_USER_INFO)
      }else{
        state.userInfo = value
        sessionStorage.setItem(process.env.VUE_USER_INFO, JSON.stringify(value))
      }

    }
  },
  actions: {

  }
})
